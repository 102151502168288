import {findLocationEntry} from '../../shared/helpers'
import {HierarchyNodeMetadata} from '../../shared/types'
import {Comment} from '../types'

export const filterCommentsByLocation = (
  comments: Comment[],
  hierarchy: HierarchyNodeMetadata,
  locationId: string
): Comment[] => {
  return comments.filter((comment) => {
    const commentLocation = findLocationEntry(hierarchy, comment.locationId)
    return commentLocation?.path.includes(locationId)
  })
}
