import {AllRunningTimeTypes, OtherRunningTimeTypes, RunningTimeType} from '@hconnect/common/types'
import {getEquipmentState, isEquipmentStatusKnown, isKilnRunning} from '@hconnect/common/utils'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import SensorsOffIcon from '@mui/icons-material/SensorsOff'
import {Box, Stack, Tooltip, Typography} from '@mui/material'
import {isBoolean, isEmpty} from 'lodash'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {PxTrendMetaData} from '../../shared/types'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {NoDataReason} from '../types'

type ProductionVolumeLabelProps = {
  status: RunningTimeType | OtherRunningTimeTypes
  onClick?: () => void
  id?: string
  name?: string
}

type PrefixIndicatorProps = {
  state: AllRunningTimeTypes
  'data-test-id'?: string
}

type NoDataReasonLabelProps = {
  pxTrendMetaData?: PxTrendMetaData
  noDataReason?: NoDataReason
  isMill: boolean
}

const PrefixIndicator: FC<PrefixIndicatorProps> = ({state, 'data-test-id': dataTestId}) => {
  return (
    <Box
      sx={({palette}) => ({
        height: 12,
        width: 12,
        marginRight: 1,
        ...(isEquipmentStatusKnown(state)
          ? {
              backgroundColor: palette.grey[500],
              borderRadius: '2px'
            }
          : isKilnRunning(state as RunningTimeType)
            ? {
                backgroundColor: palette.success.light,
                borderRadius: '50%'
              }
            : {
                backgroundColor: palette.error.light,
                borderRadius: '2px'
              })
      })}
      data-test-id={dataTestId}
    />
  )
}

export const ProductionVolumeLabel: FC<ProductionVolumeLabelProps> = ({
  status,
  onClick,
  id,
  name
}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  const equipmentState = getEquipmentState(status)

  return (
    <Tooltip
      title={
        id ? (
          <Stack>
            <Typography>
              {id} {name ? ` : ${name}` : ''}
            </Typography>
          </Stack>
        ) : (
          ''
        )
      }
    >
      <Box
        sx={() => ({
          display: 'inline-flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          cursor: onClick ? 'pointer' : 'unset'
        })}
        onClick={onClick}
        data-test-id={`production-volume-${id}`}
      >
        <PrefixIndicator
          data-test-id={`production-volume-${id}-indicator-${equipmentState}`}
          state={status}
        />

        <Typography variant="subtitle1">
          {isEquipmentStatusKnown(status) ? (
            <SensorsOffIcon color="secondary" />
          ) : isKilnRunning(status as RunningTimeType) ? (
            t(`${performancePrefix}.runningTime.label.on`)
          ) : (
            t(`${performancePrefix}.runningTime.label.off`)
          )}
        </Typography>
      </Box>
    </Tooltip>
  )
}

export const NoDataReasonLabel: FC<NoDataReasonLabelProps> = ({pxTrendMetaData, isMill}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  const {isGrindingUnit, pxTrendAvailable} = pxTrendMetaData || {}

  return (
    <Box
      sx={({palette}) => ({
        display: 'flex',
        alignItems: 'center',
        whiteSpace: {xs: 'inherit', sm: 'nowrap'},
        fontStyle: 'italic',
        color: palette.text.secondary
      })}
    >
      {isEmpty(pxTrendMetaData) || (isBoolean(pxTrendAvailable) && !pxTrendAvailable) ? (
        <>
          <CloudOffIcon sx={{m: 1}} />
          <Typography variant="body1">
            {t(`${performancePrefix}.kilnStatus.label.noPxTrend`)}
          </Typography>
        </>
      ) : isGrindingUnit && !isMill ? (
        <>
          <LinkOffIcon sx={{m: 1}} />
          <Typography variant="body1">
            {t(`${performancePrefix}.kilnStatus.label.grindingUnitOnly`)}
          </Typography>
        </>
      ) : (
        <>
          <InfoOutlinedIcon sx={{m: 1}} />
          <Typography variant="body1">
            {t(`${performancePrefix}.kilnStatus.label.noData`)}
          </Typography>
        </>
      )}
    </Box>
  )
}
