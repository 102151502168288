import {buttonOnBlueSx, CardBox} from '@hconnect/uikit/src/lib2'
import {ArrowForward} from '@mui/icons-material'
import Masonry from '@mui/lab/Masonry'
import {Box, Button, Collapse} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {HierarchyNodeType} from '../../shared/types'
import {CollapseContentContainer} from '../containers/CollapseContentContainer'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {AREA_KPI_STATUS} from '../routing'
import {HierarchyNode} from '../types'

import {CountryItemHeader, LocationTitle} from './CountryItemHeader'
import {KilnStatusTable} from './KilnStatusTable'

type CollapseLocationItemProps = {
  handleOpen: (locationId: string) => void
  index: number
  isOpen: boolean
  location: HierarchyNode
  hideDrillDownButton?: boolean
  getUrl?: (locationId: string) => string
}

type KilnStatusBoxProps = {
  country: HierarchyNode
  id: string | number
}

function KilnStatusBox({country, id}: KilnStatusBoxProps) {
  return (
    <CardBox data-test-id={`kiln-status-box-${id}`}>
      <Box display="flex" flexDirection="column">
        <CountryItemHeader
          countryData={{
            id: country.id,
            countryCode: country.countryCode,
            name: country.name
          }}
        />
        <KilnStatusTable plants={country.children} />
      </Box>
    </CardBox>
  )
}

const getStatusElement = (children: HierarchyNode[], type: HierarchyNodeType) => {
  const renderCountry = (country: HierarchyNode) =>
    country.isAllowed && <KilnStatusBox country={country} key={country.id} id={country.id} />

  if (type === HierarchyNodeType.Area || type === HierarchyNodeType.Region) {
    return children
      ?.filter(
        (region) =>
          region.isAllowed &&
          (region.type === HierarchyNodeType.Region || region.type === HierarchyNodeType.Country)
      )
      .flatMap((region) =>
        region.type === HierarchyNodeType.Region
          ? region.children.filter((child) => child.isAllowed).map(renderCountry)
          : [renderCountry(region)]
      )
  }
}

export function CollapseLocationItem({
  location,
  handleOpen,
  index,
  isOpen,
  hideDrillDownButton
}: CollapseLocationItemProps) {
  const navigate = useNavigate()
  const {allowedLocations} = useNavAccessContext()
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  const {children, id, name, isAllowed: showLocation, type} = location

  const isCompleteLocationAllowed = useMemo(
    () => !hideDrillDownButton && allowedLocations.includes(id),
    [allowedLocations, hideDrillDownButton, id]
  )

  return showLocation ? (
    <Box>
      <CollapseContentContainer
        onClick={() => handleOpen(id)}
        title={<LocationTitle title={name} />}
        action={
          isCompleteLocationAllowed && (
            <Button
              sx={buttonOnBlueSx}
              onClick={() => navigate(generatePath(AREA_KPI_STATUS, {locationId: id}))}
              startIcon={<ArrowForward />}
              data-test-id={`show-area-${id}`}
            >
              {t(`${performancePrefix}.kilnStatus.actions.showArea`, {
                area: name
              })}
            </Button>
          )
        }
        isOpen={isOpen}
        data-test-id={`chevron-button-${index}`}
      >
        <Collapse in={isOpen} timeout="auto">
          <Masonry columns={{xs: 1, sm: 1, md: 2, lg: 2, xl: 3}} spacing={3} sx={{width: 'auto'}}>
            <>{getStatusElement(children, type)}</>
          </Masonry>
        </Collapse>
      </CollapseContentContainer>
    </Box>
  ) : null
}
