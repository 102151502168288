import {FlexPage} from '@hconnect/common/components/FlexPage'
import {useWidth} from '@hconnect/common/hooks/useWidth'
import {Grid} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix, getSystemTimezone} from '../../shared/hooks'
import {TasksContainer, TasksFilters} from '../containers'
import {useTasksPageFilters} from '../hooks'

export const Tasks: React.FC = () => {
  const {t} = useTranslation()
  const {tasksPrefix} = useTranslationPrefix()
  const breakPoint = useWidth()
  const isSmall = breakPoint === 'xs' || breakPoint === 'sm'
  const timezone = getSystemTimezone()
  const [pageNumber, changePage] = useState<number>(0)

  const {filters, updateFilters} = useTasksPageFilters({
    onFiltersChanged: () => changePage(0)
  })

  return (
    <FlexPage
      useViewportHeight={!isSmall}
      title={t(`${tasksPrefix}.pageTitle`)}
      headerActionContent={
        <Grid spacing={1} container alignItems="center" justifyContent="flex-end" wrap="nowrap">
          <Grid item>
            <TasksFilters
              translationPrefix={tasksPrefix}
              filters={filters}
              onFiltersChange={updateFilters}
              timezone={timezone}
            />
          </Grid>
        </Grid>
      }
    >
      <TasksContainer
        translationPrefix={tasksPrefix}
        timezone={timezone}
        paginationOptions={{pageNumber, changePage}}
      />
    </FlexPage>
  )
}
