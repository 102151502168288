import {HierarchyNodeMetadata, HierarchyNodeType} from '../types'

export const findLocationEntry = <T extends {id: string; children: T[]}>(
  locations: T,
  locationId: string
): T | undefined => {
  if (locations.id === locationId) {
    return locations
  }
  for (const child of locations.children) {
    const result = findLocationEntry(child, locationId)
    if (result) {
      return result
    }
  }
}

export const findLocationEntryWithParents = (
  locations: HierarchyNodeMetadata,
  locationId: string,
  parents: HierarchyNodeMetadata[] = []
): HierarchyNodeMetadata[] | undefined => {
  if (locations.id === locationId) {
    return [...parents, locations]
  }

  for (const child of locations.children) {
    const result = findLocationEntryWithParents(child, locationId, [...parents, locations])
    if (result) {
      return result
    }
  }
}

export const getLocationParent = (locations: HierarchyNodeMetadata, locationId: string) => {
  const locationTree = findLocationEntryWithParents(locations, locationId)
  let parentPosition: number | undefined = -1

  if (locationTree && locationTree.length > 1) {
    const elementPosition = locationTree.findIndex((location) => location.id === locationId)
    parentPosition = elementPosition - 1

    if (locationTree[parentPosition].type === HierarchyNodeType.Region)
      parentPosition = elementPosition - 2
  }

  return parentPosition > -1 ? locationTree?.[parentPosition] : null
}
