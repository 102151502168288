import {useTheme} from '@mui/material'
import {ChartOptions} from 'chart.js'
import {isNumber} from 'lodash'
import {useMemo} from 'react'

export const useDowntimeBarChartOptions = () => {
  const theme = useTheme()
  return useMemo<ChartOptions<'bar'>>(
    () => ({
      plugins: {
        title: {
          display: false
        },
        legend: {
          position: 'bottom',
          labels: {
            padding: 21,
            usePointStyle: true,
            boxHeight: 10
          }
        },
        tooltip: {
          displayColors: false
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false
          }
        },
        y: {
          stacked: true,
          grid: {
            tickColor: theme.palette.common.white
          },
          ticks: {
            callback: (value) => {
              if (!isNumber(value) || value === 0) {
                return value
              }
              if (value >= 1000000) {
                return value / 1000000 + 'M'
              }
              if (value >= 1000) {
                return value / 1000 + 'K'
              }
              return value
            }
          }
        }
      }
    }),
    [theme.palette.common.white]
  )
}
