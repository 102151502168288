import {formatNumber} from '@hconnect/common/utils'
import {DateRange} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {InfoOutlined, TextSnippetOutlined} from '@mui/icons-material'
import {Box, Button, Stack, Tooltip, Typography, useMediaQuery, useTheme} from '@mui/material'
import {UseQueryResult} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {first, isEmpty} from 'lodash'
import moment from 'moment-timezone'
import React, {FC, useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router'

import {checkFeatureFlag, findLocationEntry} from '../../../shared/helpers'
import {usePlantTimezone} from '../../../shared/hooks/useTimezone'
import {getBarTimeFormattedLabel, getTimeStep, isUnavailable} from '../../helpers'
import {useHierarchyData} from '../../hooks/useHierarchy'
import {usePlantStoppages} from '../../hooks/usePlantStoppages'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {PLANT_DOWNTIME_KILN_STATS} from '../../routing'
import {
  AggregatedStoppage,
  AggregatedStoppageCodeStatistics,
  IssueGroupingKey,
  IssueSortingKey,
  StoppageCode,
  StoppageCodeStatistic
} from '../../types'
import {ComboChart, ComboChartData} from '../charts'
import {DataContentWrapper} from '../DataContentWrapper'

import {GroupByDropDown} from './GroupByDropDown'
import {SortBySelect} from './SortByDropDown'

const getTheTitle = (legend: string, label: string, title: string, hideInfoIcon?: boolean) => {
  return (
    <>
      {title}
      {!hideInfoIcon && (
        <Tooltip
          enterTouchDelay={0}
          title={
            <Stack spacing={0.5} ml={{sm: 1}}>
              <Typography variant="caption">{legend}</Typography>
              <Typography variant="caption">{label}</Typography>
            </Stack>
          }
        >
          <InfoOutlined sx={{color: 'secondary.main', ml: 1.5}} />
        </Tooltip>
      )}
    </>
  )
}

type TopIssuesProps = {
  plantId: string
  timeRange: DateRange<Date>
  stoppageConfig?: StoppageCode[]
  topIssuesSortingKey: IssueSortingKey
  setTopIssuesSortingKey: (key: IssueSortingKey) => void
  sortedStoppages: AggregatedStoppage[]
  aggregatedStoppagesQuery: UseQueryResult<AggregatedStoppage[], AxiosError<unknown>>
  title: string
  stoppageCodeStats: AggregatedStoppageCodeStatistics
  issueCount?: number
  hideInfoIcon?: boolean
  showFramesLabelFromTimeRange?: boolean
  onClick?: () => void
  onBarClick?: (data?: string | undefined) => void
  topIssuesGroupingKey: IssueGroupingKey
} & (
  | {
      setTopIssuesGroupingKey: (key: IssueGroupingKey) => void
      disableGroupingKey?: never
    }
  | {
      setTopIssuesGroupingKey?: never
      disableGroupingKey: boolean
    }
)

export const TopIssues: FC<TopIssuesProps> = ({
  plantId,
  timeRange,
  stoppageConfig: StoppageConfigProp,
  topIssuesSortingKey,
  setTopIssuesSortingKey,
  disableGroupingKey,
  topIssuesGroupingKey,
  setTopIssuesGroupingKey,
  sortedStoppages,
  aggregatedStoppagesQuery,
  title,
  issueCount,
  hideInfoIcon,
  showFramesLabelFromTimeRange,
  onClick,
  onBarClick,
  stoppageCodeStats
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const stoppageConfigQuery = usePlantStoppages(plantId, {enabled: !StoppageConfigProp})
  const timezone = usePlantTimezone()
  const hierarchy = useHierarchyData()
  const navigate = useNavigate()
  const isKilnKpiStatsEnabled = checkFeatureFlag('kilnKpiStats')

  const stoppageConfig = StoppageConfigProp || stoppageConfigQuery.data
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  const barLabel = t(`${performancePrefix}.downtime.label.hours`)
  const lineLabel = t(`${performancePrefix}.downtime.label.noStoppages`)

  const chartData = useMemo<ComboChartData<string> | undefined>(() => {
    if (!isEmpty(aggregatedStoppagesQuery.data) && !isEmpty(stoppageConfig)) {
      const entries = sortedStoppages.slice(0, issueCount)
      const aggregatedStoppages: string[] = entries.map(({key}) => key)

      const barData: number[] = []
      const lineData: number[] = []
      entries.forEach(({hoursStopped, stoppagesCount}) => {
        barData.push(hoursStopped)
        lineData.push(stoppagesCount ?? 0)
      })
      let columns: string[][] = []
      if (topIssuesGroupingKey === IssueGroupingKey.STOPPAGE_CODE) {
        columns = aggregatedStoppages.map((aggregatedStoppage) => {
          const stoppageCode = stoppageConfig?.find(({code}) => code === aggregatedStoppage)
          const otherCode = isUnavailable(aggregatedStoppage)
          const text = otherCode
            ? t(`${performancePrefix}.shiftEvent.stoppage.stoppageTypeOther`)
            : stoppageCode?.text
          return `${!otherCode ? aggregatedStoppage : ''}${
            text ? ` ${text.split('-')[0]}` : ''
          }`.split('-')
        })
      }
      if (topIssuesGroupingKey === IssueGroupingKey.MAIN_EQUIPMENT) {
        columns = aggregatedStoppages.map((aggregatedStoppage) => {
          if (isUnavailable(aggregatedStoppage))
            return [t(`${performancePrefix}.downtime.label.notAvailable`)]
          const stoppageCodeStat: StoppageCodeStatistic = stoppageCodeStats[aggregatedStoppage]
          return Object.values(first(stoppageCodeStat?.mainEquipments) || [])
        })
      }
      if (topIssuesGroupingKey === IssueGroupingKey.EQUIPMENT_NUMBER) {
        columns = aggregatedStoppages.map((aggregatedStoppage) => {
          if (isUnavailable(aggregatedStoppage))
            return [t(`${performancePrefix}.downtime.label.notAvailable`)]
          const stoppageCodeStat: StoppageCodeStatistic = stoppageCodeStats[aggregatedStoppage]
          return Object.values(first(stoppageCodeStat?.equipments) || [])
        })
      }
      if (topIssuesGroupingKey === IssueGroupingKey.TIME_INTERVAL) {
        const timeStep = getTimeStep(timeRange, timezone)
        columns = aggregatedStoppages.map((aggregatedStoppage) => {
          return [
            getBarTimeFormattedLabel({
              dateUtc: aggregatedStoppage,
              step: timeStep,
              language,
              timezone
            })
          ]
        })
      }
      const data = {
        columns,
        barData,
        lineData,
        barLabel,
        lineLabel,
        dataTestId: 'top-issues-chart',
        additionalData: aggregatedStoppages
      }
      return data
    }
  }, [
    aggregatedStoppagesQuery.data,
    sortedStoppages,
    issueCount,
    topIssuesGroupingKey,
    barLabel,
    lineLabel,
    stoppageConfig,
    t,
    stoppageCodeStats,
    timeRange,
    language,
    performancePrefix,
    timezone
  ])

  const retry = useCallback(() => {
    if (aggregatedStoppagesQuery.error) {
      void aggregatedStoppagesQuery.refetch()
    }
    if (stoppageConfigQuery.error) {
      void stoppageConfigQuery.refetch()
    }
  }, [aggregatedStoppagesQuery, stoppageConfigQuery])

  const getStoppageTitle = useCallback(
    (column) => {
      if (topIssuesGroupingKey === IssueGroupingKey.STOPPAGE_CODE) {
        const stoppageCode = stoppageConfig?.find(({code}) => column.includes(code))
        return stoppageCode ? stoppageCode.code : ''
      } else {
        const [equipmentId] = column.split(',')
        return equipmentId
      }
    },
    [stoppageConfig, topIssuesGroupingKey]
  )
  const getStoppageDescription = useCallback(
    (column) => {
      if (topIssuesGroupingKey === IssueGroupingKey.STOPPAGE_CODE) {
        const stoppageCode = stoppageConfig?.find(({code}) => column.includes(code))
        return stoppageCode ? stoppageCode.text : ''
      } else {
        const [, equipmentText] = column.split(',')
        return equipmentText
      }
    },
    [stoppageConfig, topIssuesGroupingKey]
  )
  const showKilnStatsButton =
    isKilnKpiStatsEnabled && hierarchy
      ? findLocationEntry(hierarchy, plantId)?.pxTrendMetaData?.isGrindingUnit === false
      : false

  const getStoppageLabel = useCallback(
    (dataIndex: number, rawValue: number) => {
      if (dataIndex === 0) {
        return `${lineLabel}: ${formatNumber(rawValue, language)}`
      }
      return `${barLabel}: ${formatNumber(rawValue, language)}`
    },
    [barLabel, language, lineLabel]
  )

  const timeFramesTitle = useMemo(() => {
    if (showFramesLabelFromTimeRange) {
      const startDate = moment.utc(timeRange.from).tz(timezone)
      const endDate = moment.utc(timeRange.to).tz(timezone)
      const singleDate = startDate.isSame(endDate, 'date')
      return timeRange.quickSelectionId
        ? `${t(`${performancePrefix}.stoppageAnalysis.label.timeframe`)}: ${t(
            `${performancePrefix}.quickSelection.${timeRange.quickSelectionId}`
          )}`
        : `${t(`${performancePrefix}.stoppageAnalysis.label.timeframe`)}: ${startDate.format(
            'ddd MMM DD YYYY'
          )}${singleDate ? '' : ` - ${endDate.format('ddd MMM DD YYYY')}`}`
    }
    return t(`${performancePrefix}.downtime.label.timeframe`, {timeframe: 12})
  }, [
    showFramesLabelFromTimeRange,
    t,
    performancePrefix,
    timeRange.from,
    timeRange.to,
    timeRange.quickSelectionId,
    timezone
  ])

  return (
    <CardBox data-test-id="top-issues">
      <Stack
        width={'100%'}
        direction={isSmall ? 'column' : 'row'}
        justifyContent="space-between"
        mb={4}
        gap={1}
      >
        <Box>
          <Typography variant={isSmall ? 'h4' : 'h3'} display="inline-flex" alignItems="center">
            {getTheTitle(
              title,
              t(`${performancePrefix}.downtime.label.unplannedStoppagesLegend`),
              timeFramesTitle,
              hideInfoIcon
            )}
          </Typography>
        </Box>
        <Box
          display="flex"
          gap={1.5}
          justifyContent="flex-end"
          sx={{
            flexFlow: {
              xs: 'column-reverse wrap',
              md: 'row wrap-reverse'
            }
          }}
        >
          {showKilnStatsButton && (
            <Box minWidth={224}>
              <Button
                color="secondary"
                onClick={() => navigate(generatePath(PLANT_DOWNTIME_KILN_STATS, {plantId}))}
                startIcon={<TextSnippetOutlined />}
                sx={{width: '100%', minHeight: 56}}
                data-test-id="show-kiln-kpi-stats"
              >
                {t(`${performancePrefix}.downtime.actions.showKilnStats`)}
              </Button>
            </Box>
          )}
          <Box display="flex" gap={1.5} sx={{flexFlow: {xs: 'column', md: 'unset'}}}>
            {!disableGroupingKey && topIssuesGroupingKey && setTopIssuesGroupingKey && (
              <Box minWidth={224}>
                <GroupByDropDown
                  value={topIssuesGroupingKey}
                  onChange={setTopIssuesGroupingKey}
                  data-test-id="top-issues-group"
                />
              </Box>
            )}

            {topIssuesGroupingKey !== IssueGroupingKey.TIME_INTERVAL && (
              <Box minWidth={224}>
                <SortBySelect
                  value={topIssuesSortingKey}
                  onChange={setTopIssuesSortingKey}
                  data-test-id="top-issues-sort"
                />
              </Box>
            )}
          </Box>
        </Box>
      </Stack>

      <Box height={280}>
        <DataContentWrapper
          showLoader={
            aggregatedStoppagesQuery.isInitialLoading || stoppageConfigQuery.isInitialLoading
          }
          data={chartData}
          retryFunction={retry}
          isRefetching={aggregatedStoppagesQuery.isRefetching || stoppageConfigQuery.isRefetching}
          isError={aggregatedStoppagesQuery.isError || stoppageConfigQuery.isError}
          progressSx={{color: 'primary.main'}}
        >
          {chartData && (
            <ComboChart
              data={chartData}
              onClick={onClick}
              showPointerBarHover={true}
              onBarClick={onBarClick}
              getTitle={getStoppageTitle}
              getAfterTitle={getStoppageDescription}
              getStoppageLabel={getStoppageLabel}
            />
          )}
        </DataContentWrapper>
      </Box>
    </CardBox>
  )
}
