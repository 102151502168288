import {WhiteList} from '@hconnect/common/hproduce'

// TODO replace this with proper Provider
export type Context = {
  whiteList: WhiteList
  user: {
    email: string
    name: string
    userId: string
    country?: null | string
    dataPrivacyAcknowledgedVersion?: string
    termsAcceptedVersion?: string
  }
  onLogout: () => void
}
