import {getTranslationKey} from '@hconnect/common/utils'
import {useTranslation} from 'react-i18next'

import {DataContentWrapper} from '../../../shared/components'
import {ShiftTaskDetails, TaskDetailsCard} from '../../components'
import {useShiftTask} from '../../hooks'
import {HProduceTask} from '../../types'
import {TaskChecklistsContainer} from '../TaskChecklistsContainer'

import {ShiftTaskAttachmentsContainer} from './ShiftTaskAttachmentsContainer'
import {ShiftTaskCommentsContainer} from './ShiftTaskCommentsContainer'

type Props = {
  task: HProduceTask
  onClose: (original?: HProduceTask, editVersion?: HProduceTask) => void
  timezone: string
  translationPrefix: string
}

export const ShiftTaskDetailsContainer = ({task, timezone, translationPrefix, onClose}: Props) => {
  const {t} = useTranslation()
  const {data, isInitialLoading, isError, refetch, isRefetching} = useShiftTask(task)

  return (
    <DataContentWrapper
      data={data}
      showLoader={isInitialLoading}
      isError={isError}
      retryFunction={refetch}
      isRefetching={isRefetching}
      translationPrefix={translationPrefix}
      noDataSettings={{
        noDataMessage: t(getTranslationKey('task.message.taskNotFound', translationPrefix)),
        onActionClick: () => onClose()
      }}
    >
      {data && (
        <TaskDetailsCard
          item={task}
          timezone={timezone}
          translationPrefix={translationPrefix}
          onClose={onClose}
        >
          <ShiftTaskDetails
            shiftTask={data}
            timezone={timezone}
            plantId={task.plantId}
            translationPrefix={translationPrefix}
          />
          <TaskChecklistsContainer task={task} translationPrefix={translationPrefix} />
          <ShiftTaskAttachmentsContainer
            attachments={data.attachments}
            plantId={task.plantId}
            translationPrefix={translationPrefix}
          />
          <ShiftTaskCommentsContainer
            eventId={task.externalId}
            plantId={task.plantId}
            timezone={timezone}
            translationPrefix={translationPrefix}
          />
        </TaskDetailsCard>
      )}
    </DataContentWrapper>
  )
}
