import {useSorting} from '@hconnect/common/hooks/useSorting'
import {StoppageDto as Stoppage} from '@hconnect/common/types'
import {
  CardBox,
  Column,
  customThemeConstants,
  DataTable,
  PaginationOptions
} from '@hconnect/uikit/src/lib2'
import {SortDirection, SxProps, Theme} from '@mui/material'
import {get, isEqual, orderBy} from 'lodash'
import React, {FC, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {TimeRange} from '../../shared/types'
import {getSalesImpactLabel} from '../helpers'
import {useStoppages} from '../hooks/useStoppages'
import {usePlantTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {StoppageEvent, StoppageItemKey} from '../types'

import {DataContentWrapper} from './DataContentWrapper'
import {DateTimePeriod} from './DateTimePeriod'

type PathParameter = {
  plantId: string
  equipmentId: string
}

const generateColumns = (
  translationFn: (key: string) => string,
  timezone: string
): Column<Stoppage>[] => [
  {
    key: 'timeFrame',
    label: translationFn('stoppageAnalysis.label.timeframe'),
    sortable: true,
    customTemplate: ({stoppageStart, stoppageEnd}) => (
      <DateTimePeriod start={stoppageStart} end={stoppageEnd} timezone={timezone} />
    )
  },
  {
    key: 'stoppageCode',
    label: translationFn('stoppageAnalysis.label.stoppageCode'),
    sortable: true,
    customTemplate: (stoppage) => ('stoppageCode' in stoppage ? stoppage.stoppageCode : '-')
  },
  // HCP-42934 uncomment when management decides
  // {
  //   key: 'cost',
  //   label: translationFn('stoppageAnalysis.label.cost'),
  //   sortable: true,
  //   customTemplate: ({cost, costCurrency}) => (cost ? `${cost} ${costCurrency}` : '-')
  // },
  {
    key: 'salesImpact',
    label: translationFn('stoppageAnalysis.label.salesImpact'),
    customTemplate: ({hasSalesImpact}) => getSalesImpactLabel(hasSalesImpact),
    sortable: true
  }
]

const sortStoppages = (
  stoppages: StoppageEvent[],
  orderByKey: StoppageItemKey,
  sortDir: SortDirection
): StoppageEvent[] =>
  orderBy(
    stoppages,
    [
      (stoppages) => {
        if (orderByKey === 'hasSalesImpact') {
          return stoppages.hasSalesImpact
        }
        if (orderByKey === 'timeFrame') {
          return stoppages.stoppageStart
        }
        return get(stoppages, orderByKey, '')?.toString().toLowerCase()
      }
    ],
    [sortDir]
  )

type StoppageAnalysisListProps = {
  setStoppageToPreview: (stoppage: StoppageEvent) => void
  stoppageToPreview?: Stoppage
}

const rowSx = (item: Stoppage, selected: boolean): SxProps<Theme> => [
  selected &&
    ((theme) => ({
      backgroundColor: theme.palette.grey[200]
    })),
  {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: customThemeConstants().palette.primaryBackgroundColor
    },
    td: {
      fontSize: 16,
      lineHeight: '40px'
    }
  }
]

export const StoppageAnalysisList: FC<StoppageAnalysisListProps> = ({
  setStoppageToPreview,
  stoppageToPreview
}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {plantId, equipmentId} = useParams<PathParameter>()
  if (!plantId || !equipmentId) throw new Error('Missing plantId or equipmentId prop')
  const {from, to} = usePlantTimeRange()
  const timezone = usePlantTimezone()
  const dateRange: TimeRange = {startDate: from.toJSON(), endDate: to.toJSON()}

  const [pageNumber, changePage] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(50)

  const columns = useMemo(
    () => generateColumns((key) => t(`${performancePrefix}.${key}`), timezone),
    [t, performancePrefix, timezone]
  )

  const {data, isInitialLoading, isError, refetch, isRefetching} = useStoppages(
    plantId,
    dateRange,
    pageNumber,
    itemsPerPage,
    equipmentId
  )

  const {sortedList, onSortClick, orderByKey, sortDir} = useSorting<StoppageEvent, StoppageItemKey>(
    {
      initialSortDir: false,
      initialOrderByKey: 'timeFrame',
      data: data?.events || [],
      sort: sortStoppages
    }
  )

  const sortDirection = !sortDir ? undefined : sortDir

  const onPaginationChange = (pageNumber: number, itemsPerPage: number) => {
    changePage(pageNumber)
    setItemsPerPage(itemsPerPage)
  }

  const paginationOptions: PaginationOptions = {
    onPaginationChange,
    page: pageNumber,
    rowsPerPage: itemsPerPage,
    totalDataLength: data?.totalItemCount || 0
  }

  return (
    <CardBox>
      <DataContentWrapper
        showLoader={isInitialLoading}
        data={data}
        retryFunction={() => void refetch()}
        isError={isError}
        isRefetching={isRefetching}
        progressSx={{color: 'primary.main'}}
      >
        <DataTable<StoppageEvent>
          columns={columns}
          data={sortedList || []}
          onSort={(event, key: StoppageItemKey) => onSortClick(key)}
          sortedBy={orderByKey}
          sortDirection={sortDirection}
          emptyMessage={
            (!isInitialLoading && t(`${performancePrefix}.plantStatus.label.noData`)) ?? ''
          }
          paginationOptions={paginationOptions}
          onRowClick={(event, item) => {
            setStoppageToPreview(item)
          }}
          rowSx={(item) => rowSx(item, isEqual(item, stoppageToPreview))}
        />
      </DataContentWrapper>
    </CardBox>
  )
}
