import {QuickSelectionId as DefaultQuickSelectionId} from '@hconnect/uikit/src/lib2/components/simpleDatePicker/dateRangePickerHelpers'

export type JanusQuickSelectionId =
  | 'lastMonth'
  | 'yearToMonthYTM'
  | 'lastYear'
  | 'currentMonth'
  | 'yearToDateYTD'

export type QuickSelectionId = DefaultQuickSelectionId | JanusQuickSelectionId

export type TimeRange = {
  startDate: string
  endDate: string
}
