import {ViewMode} from '@hconnect/common/components/eventsList/types'
import {
  noSideCard,
  sideCardSize,
  tableWithOutSideCard,
  tableWithSideCard,
  withSideCard
} from '@hconnect/common/consts'
import {ArrowKey, scrollToEvent, useArrowKeys} from '@hconnect/common/hooks/useArrowKeys'
import {useWidth} from '@hconnect/common/hooks/useWidth'
import {modeAfterClose} from '@hconnect/common/utils/cardDetailsHelpers'
import {PaginationOptions} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {CircularProgress, Grid, Theme} from '@mui/material'
import React, {useCallback, useState} from 'react'

import {TasksTable, TasksTableFilters} from '../components'
import {UseTasksTableFiltersReturn} from '../hooks'
import {HProduceTask} from '../types'

import {SideCard} from './SideCard'

const getProgressSpinner = (showBottomProgress?: boolean) =>
  showBottomProgress && (
    <CircularProgress
      sx={{marginX: 'auto', marginTop: 2}}
      data-test-id="events-view-bottom-progress"
    />
  )

type Props = {
  events: HProduceTask[]
  isLoading: boolean
  viewMode: ViewMode<HProduceTask>
  setViewMode: (mode: ViewMode<HProduceTask>) => void
  tableFiltersOptions: UseTasksTableFiltersReturn
  paginationOptions?: PaginationOptions
  timezone: string
  translationPrefix: string
  showBottomProgress?: boolean
  areFiltersApplied: boolean
}

export const TasksView: React.FC<Props> = (props) => {
  const {
    events,
    isLoading,
    setViewMode,
    paginationOptions,
    viewMode,
    translationPrefix,
    tableFiltersOptions,
    areFiltersApplied
  } = props
  const {showDescriptions, sortOptions} = tableFiltersOptions

  const [selectedTask, setSelectedTask] = useState<HProduceTask | undefined>(
    viewMode.mode === 'detailsMode' ? events.find(({id}) => id === viewMode.itemId) : undefined
  )

  const breakPoint = useWidth()

  const isSideCardShown = viewMode.mode !== 'tableOnlyMode'
  const isSmall = breakPoint === 'xs' || breakPoint === 'sm'
  const isTableShown = !isSideCardShown || !isSmall

  const onRowClicked = useCallback(
    (clickedItem: HProduceTask) => {
      setViewMode({mode: 'detailsMode', itemId: clickedItem.id})
      setSelectedTask(clickedItem)
    },
    [setViewMode]
  )

  const selectedItem =
    selectedTask ||
    (viewMode.mode === 'detailsMode' || viewMode.mode === 'editMode'
      ? events.find(({id}) => id === viewMode.itemId)
      : undefined)

  const arrowKeyHandler = useCallback(
    (arrow: ArrowKey) => {
      if (!events || !events.length) {
        return
      }

      const currentPosition = !selectedItem?.id
        ? -1
        : events.findIndex((e) => e.id === selectedItem?.id)
      let newPosition = -1
      if (arrow === 'up') {
        if (currentPosition === -1) {
          newPosition = events.length - 1
        } else {
          newPosition = (currentPosition - 1 + events.length) % events.length
        }
      }
      if (arrow === 'down') {
        if (currentPosition === -1) {
          newPosition = 0
        } else {
          newPosition = (currentPosition + 1) % events.length
        }
      }
      scrollToEvent(arrow, newPosition)

      const newEventToBeSelected = events[newPosition]
      if (newEventToBeSelected) {
        onRowClicked(newEventToBeSelected)
      }
    },
    [events, selectedItem?.id, onRowClicked]
  )

  useArrowKeys(arrowKeyHandler)

  const tableSize = (isSideCardShown ? withSideCard : noSideCard).get(breakPoint) ?? 'S'

  const doClose = (original?: HProduceTask, editVersion?: HProduceTask) => {
    const nextMode: ViewMode<HProduceTask> = modeAfterClose<HProduceTask>(original, editVersion)
    setViewMode(nextMode)
  }

  return (
    <Grid container flexGrow={1} overflow={'hidden'} spacing={2}>
      {isTableShown && (
        <Grid
          item
          height={'100%'}
          {...(isSideCardShown ? tableWithSideCard : tableWithOutSideCard)}
        >
          <CardBox
            sx={(theme: Theme) => ({
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              [theme.breakpoints.between('md', 'xl')]: {
                zoom: 0.8
              }
            })}
          >
            <TasksTableFilters
              isSmall={isSmall}
              translationPrefix={translationPrefix}
              {...tableFiltersOptions}
            />

            <TasksTable
              data={events}
              onRowClick={onRowClicked}
              paginationOptions={paginationOptions}
              sortOptions={sortOptions}
              size={tableSize}
              selectedItemId={selectedItem?.id}
              isLoading={isLoading}
              showDescriptions={showDescriptions}
              translationPrefix={translationPrefix}
              timezone={props.timezone}
              areFiltersApplied={areFiltersApplied}
            />
            {getProgressSpinner(props.showBottomProgress)}
          </CardBox>
        </Grid>
      )}
      {isSideCardShown && (viewMode.mode as string) !== 'tableOnlyMode' && (
        <Grid
          item
          {...sideCardSize}
          sx={{
            height: '100%',
            overflow: 'hidden'
          }}
        >
          <SideCard
            {...viewMode}
            task={selectedItem}
            setMode={setViewMode}
            doClose={doClose}
            timezone={props.timezone}
            translationPrefix={translationPrefix}
          />
        </Grid>
      )}
    </Grid>
  )
}
