import {EquipmentData, StoppageKind, shifthandoverStoppagesList} from '@hconnect/common/types'
import {isEmpty, isNull, isString, isUndefined} from 'lodash'
import {getI18n} from 'react-i18next'

import {
  DowntimeStoppage,
  DurationInterval,
  DurationType,
  IssueGroupingKey,
  StoppageCode
} from '../types'

export type UrlFilterParams = {
  timeRange: string
  failure?: StoppageKind[]
  mainEquipmentNumbers?: string[]
  stoppageCode?: string[]
  equipmentNumbers?: string[]
}

export const getEquipmentIds = (equipments: EquipmentData[]): string[] | undefined =>
  equipments?.map((eq) => eq.id)

export const createUrl = (selectedFilters: UrlFilterParams, path: string) => {
  const allParams = new URLSearchParams()
  for (const [key, val] of Object.entries(selectedFilters)) {
    if (!isEmpty(val)) {
      const filterValue = isString(val) ? val : val.join(',')
      allParams.append(key, filterValue)
    }
  }

  const queryString = allParams.toString()
  return `${path}${queryString ? '?' : ''}${queryString}`
}

export const getFailureKindsFromUrl = (failures?: string): StoppageKind[] | undefined => {
  if (isUndefined(failures)) return undefined
  const values = failures.split(',')
  const filteredKinds = values.filter((kind) =>
    shifthandoverStoppagesList.includes(kind as StoppageKind)
  )
  return filteredKinds.length ? (filteredKinds as StoppageKind[]) : undefined
}

export const getDurationTypesFromUrl = (durations?: string): DurationType[] | undefined => {
  if (isUndefined(durations)) return undefined
  const values = durations.split(',')
  const filteredTypes = values.filter((type) =>
    Object.values(DurationType).includes(type as DurationType)
  )
  return filteredTypes.length ? (filteredTypes as DurationType[]) : undefined
}

export const getStoppageCodesFromUrl = (stoppageCodes: string | null): string[] | undefined => {
  if (isNull(stoppageCodes) || !stoppageCodes.length) return undefined
  return stoppageCodes.split(',')
}

export const getEquipmentsFromUrl = (equipmentIds: string | null): EquipmentData[] | undefined => {
  if (isNull(equipmentIds)) return undefined
  const values = equipmentIds.split(',')
  const equipmentObjects: EquipmentData[] = []
  for (const eqId of values) {
    if (eqId) {
      equipmentObjects.push({id: eqId} as EquipmentData)
    }
  }

  return equipmentObjects.length ? equipmentObjects : undefined
}

export const isUnavailable = (aggregatedStoppage: string): boolean => {
  return aggregatedStoppage === 'not_available'
}

export const DURATION_MAP: Record<DurationType, DurationInterval> = {
  [DurationType.upTo1h]: {start: '0:00:00:00', end: '0:01:00:00'},
  [DurationType.between1hAnd24h]: {start: '0:01:00:00', end: '1:00:00:00'},
  [DurationType.moreThan24h]: {start: '1:00:00:00', end: null}
}
export const getCombinedDuration = (intervals?: DurationType[]): DurationInterval => {
  if (!intervals || intervals.length === 0) {
    return {start: '0:00:00:00', end: null}
  }
  const sortedIntervals = intervals
    .map((duration) => DURATION_MAP[duration])
    .sort((a, b) => a.start.localeCompare(b.start))

  const start = sortedIntervals[0].start
  const end = sortedIntervals.some((interval) => interval.end === null)
    ? null
    : sortedIntervals[sortedIntervals.length - 1].end

  return {start, end}
}

export const getTheTitle = ({
  performancePrefix,
  groupType,
  groupId,
  stoppageCode,
  stoppagesDetails = [],
  stoppageConfig = []
}: {
  performancePrefix: string
  groupType: IssueGroupingKey.MAIN_EQUIPMENT | IssueGroupingKey.EQUIPMENT_NUMBER
  groupId: string
  stoppageCode: string
  stoppagesDetails?: DowntimeStoppage[]
  stoppageConfig?: StoppageCode[]
}) => {
  const notAvailableLabel = getI18n().t(`${performancePrefix}.downtime.label.notAvailable`)
  const mainEquipmentText = stoppagesDetails.find((stoppage) => !!stoppage.mainEquipment?.text)
    ?.mainEquipment.text
    ? isUnavailable(groupId)
      ? notAvailableLabel
      : groupId
    : notAvailableLabel
  const equipmentNumberText = stoppagesDetails.find((stoppage) => !!stoppage.equipment?.text)
    ?.equipment.text
    ? isUnavailable(groupId)
      ? notAvailableLabel
      : groupId
    : notAvailableLabel
  const equipmentText =
    groupType === IssueGroupingKey.MAIN_EQUIPMENT ? mainEquipmentText : equipmentNumberText

  const stoppage = stoppageConfig?.find((stoppage) => stoppage.code === stoppageCode)
  const stoppageCodeText = isUnavailable(stoppageCode)
    ? getI18n().t(`${performancePrefix}.shiftEvent.stoppage.stoppageTypeOther`)
    : stoppage?.text

  return `${equipmentText} - ${stoppageCodeText}`
}
