import {StoppageFormFields} from '@hconnect/common/components/stoppageFormFields'
import {StoppageDto} from '@hconnect/common/types'
import {
  shouldNotBeEmpty,
  shouldNotBeEmptyString,
  ValidatorConfig
} from '@hconnect/common/validators'
import React from 'react'

import {useConfigData} from '../../shared/hooks/useConfig'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {mapPartialStoppage, mapStoppageDto} from '../mappers'
import {
  requiredEquipmentNumberValidator,
  stoppageCodeValidator,
  stoppageReasonValidator,
  validToValidator
} from '../validators'

type PossibleErrors = keyof StoppageDto | 'stoppageCode' | 'stoppageReason' | 'equipmentNumber'

type StoppageFormProps = {
  item: Partial<StoppageDto>
  updateMultiPart: (delta: Partial<StoppageDto>) => void
  validationError: Map<PossibleErrors, string>
  plantId: string
  children?: React.ReactNode
  withSalesImpact?: boolean
  withPriority?: boolean
  withCategory?: boolean
  durationEditOptions: {
    disableStart: boolean
    disableEnd: boolean
  }
  autocompleteProcessStage?: boolean
}

export const stoppageValidatorConfig: ValidatorConfig<StoppageDto> = new Map([
  ['title', shouldNotBeEmptyString<StoppageDto>()],
  ['equipmentNumber', requiredEquipmentNumberValidator],
  ['eventType', shouldNotBeEmpty<StoppageDto>()],
  ['stoppageCode', stoppageCodeValidator],
  ['stoppageReason', stoppageReasonValidator],
  ['stoppageStart', shouldNotBeEmpty<StoppageDto>()],
  ['stoppageEnd', validToValidator],
  ['description', shouldNotBeEmptyString<StoppageDto>()]
  // HCP-42934 uncomment when management decides
  // ['costCurrency', shouldNotBeEmptyString<Stoppage>()],
  // ['cost', costValidator]
])

export const StoppageForm: React.FC<StoppageFormProps> = ({
  children,
  item,
  updateMultiPart,
  validationError,
  withSalesImpact,
  withPriority,
  withCategory,
  durationEditOptions,
  autocompleteProcessStage
}) => {
  const {performancePrefix} = useTranslationPrefix()
  const data = useConfigData()

  return (
    <StoppageFormFields
      item={mapPartialStoppage(item, data.timezone)}
      updateMultiPart={(delta) => updateMultiPart(mapStoppageDto(delta))}
      validationError={validationError}
      timezone={data?.timezone}
      plantId={data?.plantId}
      withSalesImpact={withSalesImpact}
      withPriority={withPriority}
      withCategory={withCategory}
      hideStoppageType={true}
      stoppageCodes={{
        kilnStoppageCodes: data?.kilnStoppageCodes,
        rawMillStoppageCodes: data?.rawMillStoppageCodes,
        finishMillStoppageCodes: data?.finishMillStoppageCodes,
        alternativeFuelInstallationStoppageCodes: data?.alternativeFuelInstallationStoppageCodes
      }}
      translationPrefix={performancePrefix}
      durationEditOptions={durationEditOptions}
      autocompleteProcessStage={autocompleteProcessStage}
    >
      {children}
    </StoppageFormFields>
  )
}
