import {PlantSelect} from '@hconnect/common/components'
import plants from '@hconnect/common/hproduce/plants.json'
import {PlantSelectOption} from '@hconnect/common/hproduce/types'
import {PageContainer} from '@hconnect/uikit/src/lib2'
import React, {FC} from 'react'
import {generatePath} from 'react-router'
import {Navigate} from 'react-router-dom'

import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

type PerformancePlantSelectProps = {
  path: string
}

export const PerformancePlantSelect: FC<PerformancePlantSelectProps> = ({path}) => {
  const {allowedPlants} = useNavAccessContext()
  const {performancePrefix} = useTranslationPrefix()

  const plantsList: PlantSelectOption[] = allowedPlants.map((plantId) => {
    return {
      plantId,
      plantName: plants[plantId]?.name || plantId,
      url: generatePath(path, {plantId})
    }
  })

  if (plantsList.length === 1) {
    return <Navigate to={plantsList[0].url} />
  }

  return (
    <PageContainer>
      <PlantSelect plants={plantsList} translationPrefix={performancePrefix} />
    </PageContainer>
  )
}
