import {EventAttachment, EventAttachmentsDisplay} from '@hconnect/common/components/attachments'
import {MAX_ALLOWED_ATTACHMENTS} from '@hconnect/common/consts'
import {headlineSx, imageBoxSx} from '@hconnect/common/styles'
import {Attachment} from '@hconnect/common/types'
import {getTranslationKey, getUniqueAttachments} from '@hconnect/common/utils'
import {CardSectionTitle} from '@hconnect/uikit/src/lib2'
import {Box, Divider} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useHasPermission} from '../../../shared/hooks'

type Props = {
  attachments: Attachment[] | undefined
  plantId: string
  translationPrefix: string
}

export const ShiftTaskAttachmentsContainer = ({attachments, plantId, translationPrefix}: Props) => {
  const [activeAttachmentId, setActiveAttachmentId] = useState<number | undefined>(undefined)
  const {t} = useTranslation()
  const hasPermission = useHasPermission(plantId)
  const canViewDocuments = hasPermission('VIEW_DOCUMENTS')
  const uniqueAttachments = getUniqueAttachments(attachments, MAX_ALLOWED_ATTACHMENTS)

  if (!canViewDocuments || !uniqueAttachments) {
    return null
  }

  return (
    <>
      <CardSectionTitle sx={headlineSx()}>
        {t(getTranslationKey('shiftEvent.attachments', translationPrefix))}{' '}
        {`${uniqueAttachments.length}/${MAX_ALLOWED_ATTACHMENTS}`}
      </CardSectionTitle>
      <Divider sx={{borderColor: 'common.black'}} />
      <Box component="span" sx={imageBoxSx()}>
        {uniqueAttachments.map((attachment, index) => (
          <EventAttachment
            key={attachment.id}
            attachment={attachment}
            setActiveAttachmentId={setActiveAttachmentId}
            disableError={typeof activeAttachmentId === 'number' || !!index}
          />
        ))}
      </Box>
      {typeof activeAttachmentId === 'number' && (
        <EventAttachmentsDisplay
          attachments={uniqueAttachments}
          activeAttachmentId={activeAttachmentId}
          onClose={() => setActiveAttachmentId(undefined)}
          translationPrefix={translationPrefix}
        />
      )}
    </>
  )
}
