import {PageContainer} from '@hconnect/uikit/src/lib2'
import {BaseErrorPage} from '@hconnect/uikit/src/lib2/pages/BaseErrorPage'
import React, {Fragment} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useParams} from 'react-router'

import {findLocationEntry} from '../../shared/helpers'
import {usePlantTimezone, useTranslationPrefix} from '../../shared/hooks'
import {DataContentWrapper} from '../components'
import {DateTimeRangePicker} from '../components/DateTimeRangePicker'
import {KilnKpiStatsView} from '../components/downtime'
import {StatusPageHeader} from '../containers/StatusPageHeader'
import {useEquipmentKpiStats} from '../hooks/kpi/useEquipmentKpiStats'
import {useHierarchyData} from '../hooks/useHierarchy'
import {useStatusInitialTimeRange} from '../hooks/useStatusInitialTimeRange'
import {useTimeRange} from '../hooks/useTimeRange'
import {PLANT_DOWNTIME} from '../routing'

type PathParameter = {
  plantId: string
}

export const KilnKpiStats = () => {
  const {plantId} = useParams<PathParameter>()
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  if (!plantId) throw new Error('Missing plantId prop')
  const timezone = usePlantTimezone()
  const hierarchy = useHierarchyData()
  const dateRange = useTimeRange()
  const timeRangeLoaded = useStatusInitialTimeRange('equipmentStatistics')
  const plantPxTrendMetaData = hierarchy
    ? findLocationEntry(hierarchy, plantId)?.pxTrendMetaData
    : undefined
  const {data, isInitialLoading, isFetching, isError, refetch} = useEquipmentKpiStats(
    plantId,
    dateRange,
    {
      enabled: timeRangeLoaded && plantPxTrendMetaData && !plantPxTrendMetaData.isGrindingUnit
    }
  )

  if (!plantPxTrendMetaData)
    return <BaseErrorPage caption={t(`${performancePrefix}.error.unsupportedPlant`)} />

  if (plantPxTrendMetaData.isGrindingUnit)
    return <BaseErrorPage caption={t(`${performancePrefix}.error.grindingPlantUnsupported`)} />

  if (!timeRangeLoaded) {
    return <Fragment />
  }

  return (
    <PageContainer>
      <StatusPageHeader
        title={t(`${performancePrefix}.downtime.label.kilnKpiStats`)}
        backButtonProps={{
          target: generatePath(PLANT_DOWNTIME, {plantId}),
          targetName: t(`${performancePrefix}.downtime.actions.backToDowntime`)
        }}
        filterComponent={
          <DateTimeRangePicker
            disableCustomSelect={true}
            type="equipmentStatistics"
            timezone={timezone}
          />
        }
      />
      <DataContentWrapper
        showLoader={isInitialLoading}
        data={data}
        retryFunction={refetch}
        isError={isError}
      >
        <KilnKpiStatsView data={data || []} isLoading={isFetching} />
      </DataContentWrapper>
    </PageContainer>
  )
}
