import {
  findLocationEntry,
  findLocationEntryWithParents
} from '../../shared/helpers/location.helpers'
import {HierarchyNodeMetadata, HierarchyNodeType} from '../../shared/types'
import {HierarchyNode} from '../types'

export const checkIsArea = (location: string) => location.startsWith('a_')

export const checkIsCountry = (location: string) => location.startsWith('c_')

export const getCountries = (list: string[]) => {
  const countries = list.filter(checkIsCountry)
  return countries
}

export const getAllowedAreas = (list: string[]) => {
  const areas = list.filter(checkIsArea)
  return areas
}

export const getLocationEntryWithParentsLabel = (
  scopeLocationId: string,
  commentLocationId: string,
  hierarchy: HierarchyNodeMetadata
) =>
  findLocationEntryWithParents(hierarchy, commentLocationId)
    ?.filter(({path, id}) => path.includes(scopeLocationId) && id !== scopeLocationId)
    .map(({name}) => name)
    .join(' > ')

export const hasRegionTypeWithChildren = (node: HierarchyNode) => {
  if (node.type === HierarchyNodeType.Region && node.children?.length) {
    return true
  }
  if (Array.isArray(node.children)) {
    return node.children.some((child) => hasRegionTypeWithChildren(child))
  }
  return false
}

export const getRegionIdList = (locationId: string, hierarchy?: HierarchyNode): string[] =>
  hierarchy
    ? findLocationEntry(hierarchy, locationId)
        ?.children.filter(({isAllowed}) => isAllowed)
        .map(({id}) => id) || []
    : []
