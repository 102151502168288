import {RemoveFilterButton} from '@hconnect/common/components/filters/RemoveFilterButton'
import {textFieldOnBlueSx} from '@hconnect/common/styles'
import {Checkbox, MenuItem, TextField} from '@mui/material'
import {last} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {DurationType} from '../../types'

type DurationTypeItem = {
  type: DurationType
  description: string
}

type Props = {
  value?: DurationType[]
  onChange: (durationType?: DurationType[]) => void
  clearFilter: () => void
}

const keyName = 'duration'

export const StoppageDurationFilter: React.FC<Props> = ({value, onChange, clearFilter}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const durationTypes: DurationTypeItem[] = [
    {
      type: DurationType.upTo1h,
      description: t(`${performancePrefix}.shiftEvent.duration.upTo1h`)
    },
    {
      type: DurationType.between1hAnd24h,
      description: t(`${performancePrefix}.shiftEvent.duration.between1hAnd24h`)
    },
    {
      type: DurationType.moreThan24h,
      description: t(`${performancePrefix}.shiftEvent.duration.moreThan24h`)
    }
  ]

  return (
    <TextField
      variant="filled"
      sx={{...textFieldOnBlueSx, width: 210}}
      data-test-id="events-filter-bar-stoppage-duration"
      label={t(`${performancePrefix}.shiftEvent.addFilter.stoppageDuration`)}
      required={false}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value as unknown as DurationType[] | undefined
        if (!val) onChange(undefined)
        else {
          if (val.length === 2 && !val.includes(DurationType.between1hAnd24h)) {
            const next = val.filter((duration) => duration === last(val))
            onChange(next)
          } else {
            onChange(val)
          }
        }
      }}
      value={value ?? []}
      SelectProps={{
        multiple: true,
        renderValue: (args: unknown) =>
          (args as DurationType[])
            .map((arg: DurationType) => t(`${performancePrefix}.shiftEvent.${keyName}.${arg}`))
            .join(', ')
      }}
      select
    >
      {durationTypes.map((option) => (
        <MenuItem data-test-id={`option-${option.type}`} key={option.type} value={option.type}>
          <Checkbox
            checked={!!value?.find((duration) => duration === option.type)}
            data-test-id={`failure-checkbox-${option.type}`}
          />
          {option.description}
        </MenuItem>
      ))}
      <RemoveFilterButton
        keyName={keyName}
        removeFilter={clearFilter}
        translationPrefix={performancePrefix}
      />
    </TextField>
  )
}
