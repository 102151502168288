import {PageContainer} from '@hconnect/uikit/src/lib2'
import {Masonry} from '@mui/lab'
import {Typography} from '@mui/material'
import React, {Fragment, useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate, useParams} from 'react-router'

import {findLocationEntry, getLocationParent} from '../../shared/helpers'
import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {TimeRange} from '../../shared/types'
import {DataContentWrapper} from '../components'
import {DateTimeRangePicker} from '../components/DateTimeRangePicker'
import {PlantSummaryCard} from '../components/PlantSummaryCard'
import {StatusPageHeader} from '../containers/StatusPageHeader'
import {checkIsArea} from '../helpers/location.helpers'
import {useHierarchyData} from '../hooks/useHierarchy'
import {usePlantsRunningTimes} from '../hooks/usePlantsRunningTimes'
import {useStatusInitialTimeRange} from '../hooks/useStatusInitialTimeRange'
import {useTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {AREA_KPI_STATUS, GLOBAL_KILN_STATUS, STATUS} from '../routing'

type PathParameter = {
  locationId: string
}

export const LocationStatus: React.FC = () => {
  const {locationId} = useParams<PathParameter>()
  if (!locationId) throw new Error('Missing locationId prop')

  const {isLocationAllowed, allowMultipleLocations} = useNavAccessContext()

  const navigate = useNavigate()

  useEffect(() => {
    if (!isLocationAllowed(locationId)) {
      navigate(GLOBAL_KILN_STATUS)
    }
  }, [isLocationAllowed, locationId, navigate])

  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {from, to} = useTimeRange()
  const hierarchy = useHierarchyData()
  const location = hierarchy && findLocationEntry(hierarchy, locationId)
  const plantIds = location?.children.map(({id}) => id)

  const dateRange: TimeRange = {startDate: from.toJSON(), endDate: to.toJSON()}
  const {data, isInitialLoading, isError, refetch, isRefetching} = usePlantsRunningTimes(
    dateRange,
    plantIds || [],
    {
      enabled: !!plantIds
    }
  )
  const timeRangeLoaded = useStatusInitialTimeRange('status')

  const backButtonProps = useMemo(() => {
    const parent = hierarchy && getLocationParent(hierarchy, locationId)
    if (parent && isLocationAllowed(parent.id) && checkIsArea(parent.id)) {
      return {
        targetName: t(`${performancePrefix}.goBackLabels.backToArea`),
        target: generatePath(AREA_KPI_STATUS, {locationId: parent.id})
      }
    }

    if (allowMultipleLocations()) {
      return {
        targetName: t(`${performancePrefix}.goBackLabels.backToGlobal`),
        target: STATUS
      }
    }

    return undefined
  }, [hierarchy, allowMultipleLocations, isLocationAllowed, locationId, t, performancePrefix])

  if (!timeRangeLoaded) {
    return <Fragment />
  }

  return (
    <PageContainer>
      <DataContentWrapper
        showLoader={isInitialLoading}
        data={data}
        retryFunction={() => void refetch()}
        isError={isError}
        isRefetching={isRefetching}
      >
        <StatusPageHeader
          countryCode={location?.countryCode}
          title={location?.name}
          filterComponent={<DateTimeRangePicker type="status" disableCustomSelect />}
          backButtonProps={backButtonProps}
        />

        <Typography variant="h2" sx={{fontSize: 24, mb: 2, mt: 3, ml: 3}}>
          {t(`${performancePrefix}.locationStatus.label.plants`)}
        </Typography>

        <Masonry columns={{xs: 1, sm: 2, lg: 3}} spacing={2} sx={{width: 'auto'}}>
          {(data || []).map(({plantId, equipmentsWithRunningTimes}) => {
            const plantNode = hierarchy && findLocationEntry(hierarchy, plantId)
            return (
              plantNode && (
                <PlantSummaryCard
                  key={plantId}
                  plantNode={plantNode}
                  equipmentRunningTimes={equipmentsWithRunningTimes}
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  data-test-id={`plant-status-card-${plantId}`}
                />
              )
            )
          })}
        </Masonry>
      </DataContentWrapper>
    </PageContainer>
  )
}
