import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../shared/helpers/errorHandling'
import {useAxios} from '../../shared/hooks/useApi'
import {TimeRange} from '../../shared/types'
import {StoppageCategoryCounts} from '../types'

const QueryKey = 'stoppage-category-type-counts'

export const useCategoryTypeCounts = (
  plantId: string,
  timeRange: TimeRange,
  options?: UseQueryOptions<StoppageCategoryCounts[], AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<StoppageCategoryCounts[], AxiosError>(
    [QueryKey, timeRange, plantId],
    async () => {
      const path = `/downtime-dashboard/${plantId}/stoppages/category-types-count`
      const response = await axiosInstance.get<StoppageCategoryCounts[]>(path, {
        params: {from: timeRange.startDate, to: timeRange.endDate}
      })

      return response.data
    },
    {
      onError: (err) => handleError(err, navigate),
      ...options
    }
  )
}
