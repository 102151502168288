import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../helpers'
import {HierarchyNodeMetadata} from '../types'

import {useAxios} from './useApi'

const QueryKey = 'performance-hierarchy'

export const useHierarchy = (options?: UseQueryOptions<HierarchyNodeMetadata, AxiosError>) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<HierarchyNodeMetadata, AxiosError>(
    [QueryKey],
    async () => {
      const response = await axiosInstance.get<HierarchyNodeMetadata>('/kpi/dashboard/hierarchy')

      return response.data
    },
    {
      refetchOnMount: false,
      onError: (err) => handleError(err, navigate),
      ...options,
      onSuccess: (data) => {
        options?.onSuccess?.(data)
      },
      staleTime: Infinity
    }
  )
}
