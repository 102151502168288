import React from 'react'

import {useHierarchy, useNavAccessContext} from '../../shared/hooks'
import {setAllowedNodes} from '../helpers/hierarchy.helpers'
import {HierarchyNode} from '../types'

const hierarchyContext = React.createContext<HierarchyNode | undefined>(undefined)

export const HierarchyProvider = ({children}: {children: React.ReactNode}) => {
  const {data: hierarchy, isError, isInitialLoading} = useHierarchy()
  const {allowedPlants, allowedLocations} = useNavAccessContext()

  if (isInitialLoading || isError || !hierarchy) return null

  const allowedHierarchy = setAllowedNodes(hierarchy, allowedPlants.concat(allowedLocations))

  return <hierarchyContext.Provider value={allowedHierarchy}>{children}</hierarchyContext.Provider>
}

// used by consumers
export function useHierarchyData() {
  return React.useContext(hierarchyContext)
}
