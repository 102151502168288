import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box, Typography} from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartData,
  Legend,
  ChartDataset
} from 'chart.js'
import {orderBy} from 'lodash'
import {FC, useMemo} from 'react'
import {Bar} from 'react-chartjs-2'
import {useTranslation} from 'react-i18next'

import {TimeRange} from '../../../shared/types'
import {
  BAR_CHART_COLOR_1,
  BAR_CHART_COLOR_2,
  BAR_CHART_COLOR_3,
  BAR_CHART_COLOR_4
} from '../../consts'
import {useCategoryTypeCounts} from '../../hooks/useCategoryTypeCounts'
import {useDowntimeBarChartOptions} from '../../hooks/useDowntimeBarChartOptions'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'
import {DataContentWrapper} from '../DataContentWrapper'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

type StoppageCategoryCountsChart = {
  plantId: string
  from: Date
  to: Date
}

export const StoppageCategoryCountsChartProps: FC<StoppageCategoryCountsChart> = ({
  plantId,
  from,
  to
}) => {
  const {t} = useTranslation()
  
  const {performancePrefix} = useTranslationPrefix()

  const timeRange: TimeRange = {startDate: from.toJSON(), endDate: to.toJSON()}
  const {
    data: serverData,
    isError,
    isInitialLoading,
    refetch,
    isRefetching
  } = useCategoryTypeCounts(plantId, timeRange, {
    select: (rawData) => orderBy(rawData, 'year')
  })

  const data = useMemo<ChartData<'bar'>>(() => {
    const datasets: ChartDataset<'bar'>[] = [
      {
        label: 'Electrical',
        data: [],
        backgroundColor: BAR_CHART_COLOR_1,
        barThickness: 'flex'
      },
      {
        label: 'Mechanical',
        data: [],
        backgroundColor: BAR_CHART_COLOR_2,
        barThickness: 'flex'
      },
      {
        label: 'Process',
        data: [],
        backgroundColor: BAR_CHART_COLOR_3,
        barThickness: 'flex'
      },
      {
        label: 'Other',
        data: [],
        backgroundColor: BAR_CHART_COLOR_4,
        barThickness: 'flex'
      }
    ]
    const columns: string[] = []
    serverData?.forEach((stoppageCategoryCount) => {
      columns.push(`${stoppageCategoryCount.month} - ${stoppageCategoryCount.year}`)
      datasets[0].data.push(stoppageCategoryCount.electricalCount)
      datasets[1].data.push(stoppageCategoryCount.mechanicalCount)
      datasets[2].data.push(stoppageCategoryCount.processCount)
      datasets[3].data.push(stoppageCategoryCount.otherCount)
    })

    return {
      labels: columns,
      datasets
    }
  }, [serverData])

  const chartOptions = useDowntimeBarChartOptions()

  return (
    <CardBox height="100%" display="flex" flexDirection="column" minHeight={400}>
      <Typography variant="h3" mb={5}>
        {t(`${performancePrefix}.downtime.label.stoppagesCategoryCountsHeader`)}
      </Typography>
      <Box flex={1}>
        <DataContentWrapper
          showLoader={isInitialLoading}
          data={serverData}
          retryFunction={() => void refetch()}
          isError={isError}
          isRefetching={isRefetching}
          progressSx={{color: 'primary.main'}}
        >
          <Bar options={chartOptions} data={data} data-test-id={'stoppages-category-count-chart'} />
        </DataContentWrapper>
      </Box>
    </CardBox>
  )
}
