import React from 'react'

import {PlantEquipmentStatus} from '../../shared/types'

const plantEquipmentDataContext = React.createContext<
  | {
      plantEquipmentStatuses: Record<string, PlantEquipmentStatus>
    }
  | undefined
>(undefined)

export const PlantEquipmentDataProvider = ({
  children,
  plantEquipmentStatuses
}: {
  children: React.ReactNode
  plantEquipmentStatuses: Record<string, PlantEquipmentStatus>
}) => {
  return (
    <plantEquipmentDataContext.Provider value={{plantEquipmentStatuses}}>
      {children}
    </plantEquipmentDataContext.Provider>
  )
}

// used by consumers
export function usePlantEquipmentData() {
  const context = React.useContext(plantEquipmentDataContext)
  if (context === undefined) {
    throw new Error('usePlantEquipmentData must be used within a PlantEquipmentDataProvider')
  }
  return context
}
