import {formatNumber} from '@hconnect/common/utils'
import {Column, DataTable, CardBox, ListTable} from '@hconnect/uikit/src/lib2'
import {Theme, useMediaQuery} from '@mui/material'
import moment from 'moment-timezone'
import React, {useMemo, FC} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../../shared/hooks'
import {EquipmentKpiStats} from '../../types'

type KilnKpiStatsViewProps = {
  data: EquipmentKpiStats[]
  isLoading: boolean
}

export const KilnKpiStatsView: FC<KilnKpiStatsViewProps> = ({data, isLoading}) => {
  const {t, i18n} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const columns = useMemo<Column<EquipmentKpiStats>[]>(
    () => [
      {
        key: 'date',
        label: t(`${performancePrefix}.downtime.label.month`),
        customTemplate: (row) =>
          `${isMobile ? `${t(`${performancePrefix}.downtime.label.month`)}: ` : ''}${moment(
            row.month
          ).format('MM/YY')}`
      },
      {
        key: 'calendarHours',
        label: t(`${performancePrefix}.downtime.label.calendarHours`),
        customTemplate: (row) =>
          `${isMobile ? `${t(`${performancePrefix}.downtime.label.calendarHours`)}: ` : ''}${
            row.calendarHours
          }`
      },
      {
        key: 'stoppageCount',
        label: t(`${performancePrefix}.downtime.label.stoppageCount`),
        customTemplate: (row) => row.incidentCount + row.circumstanceCount
      },
      {
        key: 'unplannedStoppageHours',
        label: t(`${performancePrefix}.downtime.label.unplannedStoppage`)
      },
      {
        key: 'plannedStoppageHours',
        label: t(`${performancePrefix}.downtime.label.plannedStoppage`)
      },
      {
        key: 'runtimeHours',
        label: t(`${performancePrefix}.downtime.label.runtime`)
      },
      {
        key: 'equipmentReliabilityCoefficient',
        label: t(`${performancePrefix}.downtime.label.ytmReliability`),
        customTemplate: ({equipmentReliabilityCoefficient}) =>
          equipmentReliabilityCoefficient
            ? formatNumber(equipmentReliabilityCoefficient * 100, i18n.language, 2)
            : '-'
      },
      {
        key: 'meanTimeBetweenFailures',
        label: t(`${performancePrefix}.downtime.label.ytmMtbf`),
        customTemplate: ({meanTimeBetweenFailures}) =>
          meanTimeBetweenFailures
            ? formatNumber(meanTimeBetweenFailures * 100, i18n.language, 2)
            : '-'
      },
      {
        key: 'equipmentOperatingCoefficient',
        label: t(`${performancePrefix}.downtime.label.ytmProcessCoefficient`),
        customTemplate: ({equipmentOperatingCoefficient}) =>
          equipmentOperatingCoefficient
            ? formatNumber(equipmentOperatingCoefficient * 100, i18n.language, 2)
            : '-'
      },
      {
        key: 'productionInTonnes',
        label: t(`${performancePrefix}.downtime.label.ytmClinkerProduction`),
        customTemplate: ({productionInTonnes}) =>
          productionInTonnes ? formatNumber(productionInTonnes, i18n.language, 2) : '-'
      }
    ],
    [i18n.language, isMobile, performancePrefix, t]
  )

  return (
    <>
      {isMobile ? (
        <ListTable data={data || []} columns={columns} />
      ) : (
        <CardBox data-test-id="kiln-stats-table-container">
          <DataTable<EquipmentKpiStats> columns={columns} data={data || []} loading={isLoading} />
        </CardBox>
      )}
    </>
  )
}
