import {RunningTimeType} from '@hconnect/common'

import {LocationType} from './location.types'
import {PerformanceDashboardEquipmentTypes} from './operationHours.types'

export type PxTrendMetaData = {
  isGrindingUnit: boolean
  productionVolumesAvailable: boolean
  pxTrendAvailable: boolean
  runinngTimesAvailable: boolean
  siloFillLevelsAvailable: boolean
}

export type Plant = {
  plantId: string
  name: string
  lastUpdate?: string
  parentLocationId?: string
  parentLocationType: LocationType
  timezone?: string
  countryCode: string
  countryName: string
  pxTrendMetaData?: PxTrendMetaData
}

export type PlantStatusEquipment = {
  id: string
  name: string
  matchingId: string
  type: PerformanceDashboardEquipmentTypes
  status: RunningTimeType
  productionVolume: number
}

export type PlantEquipmentStatus = {
  plant: Plant // soon deprecated
  equipments: PlantStatusEquipment[]
  actualCementProduction: number // soon deprecated
  actualClinkerProduction: number // soon deprecated
  plantId: string
}
