export enum StoppageAnalysisPageReferer {
  STATUS = 'status',
  PERFORMANCE = 'performance',
  PROCESS_ENGINEER_DASHBOARD = 'process-engineer-dashboard',
  PROCESS_ENGINEER_PLANT_STATUS = 'process-engineer-plant-status',
  RELIABILITY_ENGINEER_DASHBOARD = 'reliability-engineer-dashboard',
  RELIABILITY_ENGINEER_PLANT_STATUS = 'reliability-engineer-plant-status',
  OPTIMIZATION_SPECIALIST_DASHBOARD = 'optimization-specialist-dashboard'
}

export enum PlantStatusPageReferer {
  STATUS = 'status',
  PERFORMANCE = 'performance',
  PROCESS_ENGINEER_DASHBOARD = 'process-engineer-dashboard',
  RELIABILITY_ENGINEER_DASHBOARD = 'reliability-engineer-dashboard'
}
