import {Location} from '../../shared/types'

import {KpisList} from './kilnStatus.types'

export type Comment = {
  id: string
  text: string
  creatorId: string
  createdAt: string
  locationId: string
  topic: KpisList
  delta: number
  userSelectedTimestampFrom: string
  userSelectedTimestampTo: string
  creatorName?: string
}

export type NewComment = {
  text: string
  delta: number
  topic: KpisList
  userSelectedTimestampFrom: string
  userSelectedTimestampTo: string
}

export type CommentLocationData = {
  locationData: Location
  delta: number
  actualCurrentValue: number
  plannedCurrentValue: number
  unit: string
}
