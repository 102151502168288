import {ContentBox} from '@hconnect/common/components/ContentBox'
import {EquipmentRunningTimesResult} from '@hconnect/common/components/kpiPerformance/EquipmentRunningTimesResult'
import {OperationHoursLegendTooltip} from '@hconnect/common/components/runningTimes'
import {mapRunningTimeDto} from '@hconnect/common/mappers'
import {
  EquipmentData,
  EquipmentRunningTimesDto as EquipmentRunningTimes,
  RunningTimeDto as RunningTime
} from '@hconnect/common/types'
import {getTranslationKey} from '@hconnect/common/utils/translation.utils'
import {DateRange} from '@hconnect/uikit'
import {Typography} from '@mui/material'
import {get, isEmpty, last} from 'lodash'
import React, {FC, ReactNode, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {mapEquipmentRunningTimes} from '../../shared/mappers'
import {TimeRange} from '../../shared/types'
import {PlantStatusAnalyticsEventName, useTrackPlantStatusAnalyticsEvent} from '../helpers'
import {useRunningTimes} from '../hooks/useRunningTimes'
import {usePlantTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

type PlantRunningTimesProps = {
  plantId: string
  getStackedBarChartComponent: (equipment: EquipmentData, runningTimes: RunningTime[]) => ReactNode
  stoppageAnalysisPath: string
}

export const PlantRunningTimes: FC<PlantRunningTimesProps> = ({
  plantId,
  getStackedBarChartComponent,
  stoppageAnalysisPath
}) => {
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const navigate = useNavigate()
  const trackAnalyticsEvent = useTrackPlantStatusAnalyticsEvent()

  const {from, to}: DateRange<Date> = usePlantTimeRange()
  const timeRange: TimeRange = useMemo(
    () => ({startDate: from.toJSON(), endDate: to.toJSON()}),
    [from, to]
  )

  const {
    data: runningTimePerformances,
    isInitialLoading: isRunningTimePerformanceLoading,
    error: runningTimePerformancesError
  } = useRunningTimes(timeRange, plantId)

  const redirectToStoppageAnalysis = (equipmentId: string) => {
    trackAnalyticsEvent(PlantStatusAnalyticsEventName.userGoesToStoppageAnalysisPage, {
      equipmentId
    })
    navigate(
      generatePath(stoppageAnalysisPath, {
        plantId,
        equipmentId
      })
    )
  }

  const errorMessage = runningTimePerformancesError
    ? t(`${performancePrefix}.error.label.fetchingError`)
    : undefined

  return (
    <ContentBox
      mode="max100PercentOfParentHeight"
      title={
        <Typography component="span" variant="h3" display="flex" alignItems="center">
          <>
            {t(getTranslationKey('runningTimes.label.title', performancePrefix))}
            {!isEmpty(runningTimePerformances) && (
              <OperationHoursLegendTooltip translationPrefix={performancePrefix} />
            )}
          </>
        </Typography>
      }
      isLoading={isRunningTimePerformanceLoading}
      bodyWithPadding
      errorMessage={errorMessage}
      minHeight="150px"
      data-test-id="plant-running-times"
    >
      {runningTimePerformances?.map(
        (equipmentPerformanceData: EquipmentRunningTimes, index: number) => {
          const activeStatus = get(last(equipmentPerformanceData.runningTimes), 'runningTimeType')
          return (
            <EquipmentRunningTimesResult
              translationPrefix={performancePrefix}
              runningTimePerformance={mapEquipmentRunningTimes([equipmentPerformanceData])[0]}
              equipment={equipmentPerformanceData.equipment}
              key={equipmentPerformanceData.equipment.id}
              getStackedBarChartComponent={(equipment, runningTimes) =>
                getStackedBarChartComponent(equipment, runningTimes.map(mapRunningTimeDto))
              }
              hideDivider={runningTimePerformances?.length === index + 1}
              activeStatus={activeStatus}
              onClick={() => redirectToStoppageAnalysis(equipmentPerformanceData.equipment.id)}
            />
          )
        }
      )}
    </ContentBox>
  )
}
