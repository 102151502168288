import {DateRange} from '@hconnect/uikit/src/common'
import {useQuery, UseQueryOptions} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useNavigate} from 'react-router-dom'

import {handleError} from '../../../shared/helpers/errorHandling'
import {useAxios} from '../../../shared/hooks/useApi'
import {PERFORMANCE_KPI_REQUEST_STALE_TIME} from '../../consts'
import {EquipmentKpiStats} from '../../types'

const QueryKey = 'equipment-kpi-stats'

export const useEquipmentKpiStats = (
  plantId: string,
  dateRange: DateRange<Date>,
  options?: UseQueryOptions<EquipmentKpiStats[] | null, AxiosError>
) => {
  const axiosInstance = useAxios()
  const navigate = useNavigate()

  return useQuery<EquipmentKpiStats[] | null, AxiosError>({
    queryKey: [QueryKey, dateRange, plantId],
    queryFn: async () => {
      const response = await axiosInstance.get<EquipmentKpiStats[]>(
        `/kpi-result/${plantId}/cockpit-ytm`,
        {
          params: {
            fromYear: dateRange.from.getFullYear(),
            fromMonth: dateRange.from.getMonth() + 1,
            toYear: dateRange.to.getFullYear(),
            toMonth: dateRange.to.getMonth() + 2 // BE expects the next month
          }
        }
      )
      return response.data
    },
    onError: (err) => handleError(err, navigate),
    staleTime: PERFORMANCE_KPI_REQUEST_STALE_TIME,
    ...options
  })
}
