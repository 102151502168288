import {omit} from 'lodash'

import {HierarchyNodeMetadata} from '../../shared/types'
import {HierarchyNode} from '../types'

export function createNewTreeWithIsAllowedProperty(node: HierarchyNodeMetadata): HierarchyNode {
  const newNode: HierarchyNode = {...omit(node, 'children'), isAllowed: false, children: []}
  newNode.children = node.children.map((child) => createNewTreeWithIsAllowedProperty(child))
  return newNode
}

export function setAllowedNodes(
  tree: HierarchyNodeMetadata,
  allowedLocations: string[]
): HierarchyNode {
  const idSet = new Set(allowedLocations)
  const hierarchyTree = createNewTreeWithIsAllowedProperty(tree)

  function traverse(node: HierarchyNode) {
    if (idSet.has(node.id)) {
      node.isAllowed = true
      setAncestorsAllowed(hierarchyTree, node)
      setChildrenAllowed(node)
    }

    node.children.forEach((child) => traverse(child))
  }

  function setAncestorsAllowed(root: HierarchyNode, node: HierarchyNode) {
    if (!root) return false

    if (root === node) {
      root.isAllowed = true
      return true
    }

    for (const child of root.children) {
      const result = setAncestorsAllowed(child, node)
      if (result) {
        child.isAllowed = true
        return true
      }
    }

    return false
  }

  function setChildrenAllowed(node) {
    if (!node) return
    node.children.forEach((child) => {
      child.isAllowed = true
      setChildrenAllowed(child)
    })
  }

  traverse(hierarchyTree)
  // set global node to allowed if any of its children are allowed
  return {
    ...hierarchyTree,
    isAllowed: hierarchyTree.children.some((child) => child.isAllowed)
  }
}
